import type { Note } from '@prisma/client'

export const stageName = (stage: Note['stage']) => {
	switch (stage) {
		case 'Seedling':
			return '🌱 Seedling'
		case 'Budding':
			return '🌿 Budding'
		case 'Evergreen':
			return '🌲 Evergreen'
	}
}
